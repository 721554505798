<template>
  <spinner-container :loading="localLoading">
    <back-btn />
    <v-container fluid>
      <v-row justify="center">
        <v-col lg="10">
          <v-card class="card--shadow">
            <counseling-form
              :counselingData="counselingEachData"
              @emitData="updateCounseling"
            />
            <feedback
              :authorId="admin.id"
              :feedbacks="feedbacks"
              @createComment="createComment"
              @deleteComment="deleteComment"
            />
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </spinner-container>
</template>

<script>
import CounselingForm from "@/components/counseling/CounselingForm";
import Feedback from "@/components/feedback/Feedback";
import { mapState } from "vuex";
import SpinnerContainer from "@/components/container/SpinnerContainer.vue";
import BackBtn from "@/components/navigation/BackBtn.vue";

export default {
  components: {
    CounselingForm,
    SpinnerContainer,
    BackBtn,
    Feedback,
  },
  computed: {
    ...mapState("Counseling", ["counselingEachData"]),
    ...mapState("Loading", ["localLoading"]),
    ...mapState("Feedback", ["feedbacks"]),
    ...mapState("Admin", ["admin"]),
  },
  methods: {
    createComment(comment) {
      const newComment = {
        item_id: this.$route.params.id,
        item_type: "COUNSELING",
        author_id: this.admin.id,
        author_type: "PA",
        body: comment,
      };
      this.$store.dispatch("Feedback/create", newComment);
    },
    deleteComment(id) {
      this.$store.dispatch("Feedback/delete", id);
    },
    updateCounseling(data) {
      this.$store.dispatch("Counseling/update", data).then(() => {
        this.$router.push(
          `/counseling/activity/${this.$route.params.month}/${this.$route.params.year}`
        );
      });
    },
  },
  created() {
    this.$store.dispatch("Loading/changeLocalLoading");
    this.$store.dispatch("Feedback/getAll", {
      item_id: this.$route.params.id,
      item_type: "COUNSELING",
    });
    this.$store
      .dispatch("Counseling/getEach", this.$route.params.id)
      .then(() => {
        this.$store.dispatch("Loading/changeLocalLoading");
      });
  },
};
</script>
